html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

*:focus {
  outline: none;
}

.hidden {
  display: none !important;
}

input::placeholder {
  text-transform: initial;
}

fieldset {
  padding: 0px 8px;
}

b {
  font-weight: 500;
}

.waiting {
  text-align: center;
  padding: 30px;
  margin: 30px;
  animation: 5s waitingIn;
  animation-fill-mode: forwards;
  background-color: #DDD;
  border: 1px solid #AAA;
  border-radius: 10px;
  opacity: 0;
}

@keyframes waitingIn {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
