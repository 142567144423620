
@font-face {
  font-family: 'Lufthansa Light';
  font-style: normal;
  font-weight: 400;
  src: local('Lufthansa Light'), local('LufthansaLight'),
  url('resources/fonts/lufthansa-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('resources/fonts/lufthansa-light-webfont.woff') format('woff'), /* Modern Browsers */
  url('resources/fonts/lufthansa-light-webfont.svg#lhLight') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lufthansa Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Lufthansa Medium'), local('LufthansaMedium'),
  url('resources/fonts/lufthansa-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
  url('resources/fonts/lufthansa-medium-webfont.woff') format('woff'), /* Modern Browsers */
  url('resources/fonts/lufthansa-medium-webfont.svg#lhLight') format('svg'); /* Legacy iOS */
}
